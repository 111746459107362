import '../AhaGuru_CardDesigns/Styles/AhaGuru_cardDesign2.css';
import img2 from "./assests/img2.jpg"
import { BsPcDisplay } from "react-icons/bs";
export function AhaGuruCard2(){
    return(
        <div className="mainDiv2">
            <div className="cardTop2">
                <img src={img2} alt="image"/>
            </div>
            <div className="cardBottom2">

                <div className='classDetail2'>
                    <h4>Class 11</h4>
                    <div className='classIcon2'>
                        <BsPcDisplay />
                    </div>
                </div>
            
                <h3>LOGARITHMS</h3>
                <ul className="courseDetails2">
                    <li><span>4</span> Skill Builders</li>
                    <li><span>87</span> Concepts & Solved Videos</li>
                    <li><span>7</span> Hours of Learning</li>
                    <li>Certificate of Completion</li>
                </ul>

                <div className="priceDetails2">
                    <div className="price2">
                        <h3>Rs. 799<del className='notPrice2'>Rs. 2000</del></h3>
                    </div>
                    <div className="link2">
                        <a href="#">View Details</a>
                    </div>
                </div>

                <div className="buyNowbtn2">
                    <button>Buy Now</button>
                </div>
            </div>
        </div>
    )
}