import React from 'react'
import {AhaGuruCard1} from "./AhaGuru_cardDesign1"
import {AhaGuruCard2} from "./AhaGuru_cardDesign2"
import {AhaGuruCard3} from "./AhaGuru_cardDesign3"
import {AhaGuruCard4} from "./AhaGuru_cardDesign4"
import {AhaGuruCard5} from "./AhaGuru_cardDesign5"
import {AhaGuruCard6} from "./AhaGuru_cardDesign6"
import {AhaGuruCard7} from "./AhaGuru_cardDesign7"
const CardsAhaguru = () => {
  return (
    <div className='cardscss'>  
                        <AhaGuruCard1/>
                        <AhaGuruCard2/>
                        <AhaGuruCard3/>
                        <AhaGuruCard4/>
                        <AhaGuruCard5/>
                        <AhaGuruCard6/>
                        <AhaGuruCard7/>
    </div>
  )
}

export default CardsAhaguru
