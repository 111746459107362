import '../AhaGuru_CardDesigns/Styles/AhaGuru_cardDesign7.css';
import { FaLaptop } from "react-icons/fa";
import { IoPersonSharp } from "react-icons/io5";
import { BsPcDisplay } from "react-icons/bs";
import { PiLaptopFill } from "react-icons/pi";
import { IoNewspaperOutline } from "react-icons/io5"
import { FaCalculator } from "react-icons/fa6";
import { BsFillPrinterFill } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { HiPencilAlt } from "react-icons/hi";
import { FaMobile } from "react-icons/fa6";

export function AhaGuruCard7(){
    return(
        <div className="mainDiv7">
            <div className="cardTop7">
                <div className='classdetailsHeader7'>
                    <h5>LIVE ONLINE CLASES</h5>
                    <h5>(2025-26)</h5>
                </div>    
            </div>
            <div className="cardBottom7">
                <p className='classHeader7'>Class 8 to 12</p>
                <p className='para7'>Learn and Interact live with India's Best Teachers from the comfort of your home.Ask and Clarify doubts instantly during the live clases.</p>
                <p><FaLaptop className='CardIcons'/> Periodic LIVE Webinar Sessions</p>
                <hr className="customDivider"/>
                <p><IoPersonSharp className='CardIcons'/> Doubt Clarifying Support</p>
                <hr className="customDivider"/>
                <p><BsPcDisplay className='CardIcons'/>  Video Lessons</p>
                <hr className="customDivider"/>
                <p><IoNewspaperOutline className='CardIcons'/> Solved Video Examples</p>
                <hr className="customDivider"/>
                <p><PiLaptopFill className='CardIcons'/> Online Recorded Video Lessons</p>
                <hr className="customDivider"/>
                <p><FaCalculator className='CardIcons'/> Practice Questions</p>
                <hr className="customDivider"/>
                <p><BsFillPrinterFill className='CardIcons'/> Printed Study Materials</p>
                <hr className="customDivider"/>
                <p><FaHandshake className='CardIcons'/> Personalized Mentor Suppourt</p>
                <hr className="customDivider"/>
                <p><HiPencilAlt className='CardIcons'/>  Monthly Consolidated Tests</p>
                <hr className="customDivider"/>
                <p><FaMobile className='CardIcons'/>Parents App to View Progress Reports</p>

                <div className="buyNowbtn7">
                    <button className='takeCourseBtn7'>View Details</button>
                </div>
            </div>
        </div>
    )
}