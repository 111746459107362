import React from 'react'
import '../../src/components/ug_homepage/components/Downloads/styles/DocumentUpload.css'
import { useNavigate } from 'react-router-dom';
const UploadFormatsMain = () => {
   const navigate = useNavigate();
  
  const handleJeeMainsClick=()=>{
    navigate('/DocumentUpload');
  }
  const handleBitsatClick=()=>{
    navigate('/BitSat');
  }
  const handleNeetClick=()=>{
    navigate("/UploadNeetDocument")
  }

  return (
    <div>
 
  <div className="SectionButtonsmaindiv">
     <button onClick={handleJeeMainsClick} className='SectionButtons'>JEE Mains</button>
     <button onClick={handleBitsatClick} className='SectionButtons'>Bitsat</button>
     <button onClick={handleNeetClick} className='SectionButtons'>NEET</button>
  </div>
  <div className="maindivInstructions">
        {" "}
        <h3>Instructions</h3>
        <ul>
          {" "}
          <li>
            1.JEE Mains exam includes two Sections with
              MCQs (Multiple Choice Questions) Each question has four options, and only one is correct.
               and NVQs (Numerical Value Questions) Instead of selecting an option, candidates must enter a numerical answer.
          

          </li>{" "}
          <li>
            2.BITSAT is a computer-based exam with four sections,All questions in BITSAT are MCQs, and candidates must choose the correct option. There are no numerical value-based questions
          </li>{" "}
          <li>
            3.NEET is a medical entrance exam with three major sections,All questions in NEET are MCQs, with four options per question.
          </li>
          <li>
            4.Clicking on JEE Main directs you to Section A(MCQs)and Section B(NVQs)
              Clicking on BITSAT directs you to Section A (MCQs)
              Clicking on NEET directs you to Section A(MCQs)and Section B(NVQs)
          </li>
          <li>
            <strong>Steps to Follow:</strong>
          </li>{" "}
          <li>
            1.First, take a screenshot of the question and paste it into the{" "} <b>Question</b> section.
          </li>{" "}
          <li>
           2. Next, take a screenshot of Option A and paste it into the{" "}<b>Option A</b>
            section. Please repeat this step for the other options (Option B,
            Option C, etc.).
          </li>{" "}
          <li>
            3.Afterward, take a screenshot of the solution and paste it into the{" "}
            <b>Solution</b> section.
          </li>{" "}
          <li>
            4.Type the correct answer in the <b>Answer</b> text box provided.
          </li>{" "}
          <li>
            5.If you need to add another question, simply click on the <b>Add New Quetion</b>{" "}
            button to create a new question field.
          </li>{" "}
          <li>
            6.Continue following this process until all the questions are added—up
            to 20 questions for Section A
          </li>{" "}
          <li>
            7.Once you have completed entering the questions, click on the{" "}
            <b>Save</b>{" "}
            button to preview the document. You can review your entries, and if
            you find any errors or need modifications, navigate to the specific
            question and make the necessary changes.
          </li>{" "}
          <li>
            8.Finally, when you are satisfied with the document, click on the
            {" "}<b>Download</b> button. The document will be downloaded in the
            required format directly to your system.
          </li>{" "}
        </ul>
        
      </div>
    </div>
  )
}

export default UploadFormatsMain
