import React from 'react'
import '../../src/components/ug_homepage/components/Downloads/styles/DocumentUpload.css'
import { useNavigate } from 'react-router-dom';
const BitSatSectionA = () => {
    const navigate = useNavigate();
    
    const handleClick=()=>{
      navigate('/SectionADocumentCreationBitsat');
    }
  return (
    <div>
      <div>
        <div className="SectionButtonsmaindiv">
            <button onClick={handleClick} className='SectionButtons'>Add Section A</button>
        </div>
        <div className="maindivInstructions">
        {" "}
        <h3>Instructions</h3>
        <ul>
          {" "}
          <li>
            1.If you wish to create a document for MCQs, please click on the{" "}
            <b>Add Section A</b> button.
          </li>{" "}
          <li>
            2.In Section A, you will be able to create a document containing up to
            20 MCQ questions.
          </li>{" "}
          
          <li>
            <strong>Steps to Follow:</strong>
          </li>{" "}
          <li>
            1.First, take a screenshot of the question and paste it into the{" "} <b>Question</b> section.
          </li>{" "}
          <li>
           2. Next, take a screenshot of Option A and paste it into the{" "}<b>Option A</b>
            section. Please repeat this step for the other options (Option B,
            Option C, etc.).
          </li>{" "}
          <li>
            3.Afterward, take a screenshot of the solution and paste it into the{" "}
            <b>Solution</b> section.
          </li>{" "}
          <li>
            4.Type the correct answer in the <b>Answer</b> text box provided.
          </li>{" "}
          <li>
            5.If you need to add another question, simply click on the <b>Add New Quetion</b>{" "}
            button to create a new question field.
          </li>{" "}
          <li>
            6.Continue following this process until all the questions are added—up
            to 20 questions for Section A
          </li>{" "}
          <li>
            7.Once you have completed entering the questions, click on the{" "}
            <b>Save</b>{" "}
            button to preview the document. You can review your entries, and if
            you find any errors or need modifications, navigate to the specific
            question and make the necessary changes.
          </li>{" "}
          <li>
            8.Finally, when you are satisfied with the document, click on the
            {" "}<b>Download</b> button. The document will be downloaded in the
            required format directly to your system.
          </li>{" "}
        </ul>
        
      </div>
      </div>
    </div>
  )
}

export default BitSatSectionA
