import '../AhaGuru_CardDesigns/Styles/AhaGuru_cardDesign3.css';
import teacher1 from "./assests/teacher1.webp"
import { MdLaptopWindows } from "react-icons/md";
import { IoNewspaperOutline } from "react-icons/io5";
import { BsPcDisplay } from "react-icons/bs";
import { PiCertificateFill } from "react-icons/pi";
import { MdSquare } from "react-icons/md";

export function AhaGuruCard3(){
    return(
        <div className="mainDiv3">
            <div className="cardTop3">
                <div className='cardImgHolder3'>
                    <img src={teacher1} alt="image"/>
                </div>
                <div className='classdetailsHeader3'>
                    <h5>Class 11</h5>
                    <h5>Physics for IIT JEE + CBSE + NEET</h5>
                </div>    
            </div>
            <div className="cardBottom3">
                <p> <MdLaptopWindows className='CardIcons'/> 225 Concept Video Lectures</p>
                <hr className="customDivider" />
                <p><IoNewspaperOutline className='CardIcons'/>497 Solved Video Examples</p>
                <hr className="customDivider" />
                <p><BsPcDisplay className='CardIcons'/> 362 Practice Questions</p>
                <hr className="customDivider" />
                <p><PiCertificateFill className='CardIcons'/>  Certificate of Completion</p>

                <div className="priceDetails3">
                    <div className="price3">
                        <h4>Rs. 8500<del className='notPrice3'>Rs. 12000</del></h4>
                    </div>
                    <div className="link3">
                        <a href="#">View Topics</a>
                    </div>
                </div>

                <div className="buyNowbtn3">
                    <button className='takeCourseBtn3'><MdSquare  className='squareIcon'/>Take this Course</button>
                </div>
            </div>
        </div>
    )
}