import { useState } from "react";
import '../AhaGuru_CardDesigns/Styles/AhaGuru_cardDesign4.css';

export function AhaGuruCard4 ()  {
  const [progress, setProgress] = useState(48); 
  const circumference = 251.2;
  const offset = circumference * (1 - progress / 100);

  return (
    <div className="progress-card-container">
      <div className="progress-card">
    
        <div className="progress-circle">
          <svg width="100" height="100" viewBox="0 0 100 100">
         
            <circle cx="50" cy="50" r="40" stroke="#EAEAEA" strokeWidth="6" fill="none" />
      
            <g transform="rotate(-90, 50, 50)">
              <circle
                cx="50"
                cy="50"
                r="40"
                stroke="#4CAF50"
                strokeWidth="6"
                fill="none"
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                strokeLinecap="round"
                className="progress-ring"
              />
            </g>
          
            <text x="50" y="50" textAnchor="middle" dominantBaseline="middle" className="progress-text">
              {progress}%
            </text>
          </svg>
        </div>

        <p className="class-title">CLASS</p>
        <p className="class-name">ZERO POTENTIAL METHOD FOR RESISTORS AND CAPACITORS</p>
        <p className="instructor">Dr. Balaji Sampath</p>

        <button
          className="progress-btn"
          onClick={() => setProgress((prev) => (prev >= 100 ? 0 : prev + 10))}
        >
          CONTINUE
        </button>
      </div>
    </div>
  );
};


