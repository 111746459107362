import React, { useState } from "react";
import { saveAs } from "file-saver";
import { Document, Packer, TextRun, Paragraph, ImageRun } from "docx";

const SectionADocumentCreationBitSat = () => {
    const [questions, setQuestions] = useState([
        {
            questionImage: null,
            options: [
                { text: "", image: null },
                { text: "", image: null },
                { text: "", image: null },
                { text: "", image: null },
            ],
            solutionImage: null,
            answer: "",
        },
    ]);

    const [questionBgColors, setQuestionBgColors] = useState(["#fff"]); 
    const [solutionBgColors, setSolutionBgColors] = useState(["#fff"]); 
    const [optionBgColors, setOptionBgColors] = useState([["#fff", "#fff", "#fff", "#fff"]]); 
    const [questionActiveStatuses, setQuestionActiveStatuses] = useState([false]); 
    const [solutionActiveStatuses, setSolutionActiveStatuses] = useState([false]); 
    const [optionActiveStatuses, setOptionActiveStatuses] = useState([[false, false, false, false]]); 

    const processImage = (imageData, maxWidth, maxHeight) => {
        const img = new Image();
        img.src = imageData;

        return new Promise((resolve) => {
            img.onload = () => {
                const { naturalWidth, naturalHeight } = img;
                let width = naturalWidth;
                let height = naturalHeight;

                if (naturalWidth > maxWidth || naturalHeight > maxHeight) {
                    if (naturalWidth / maxWidth > naturalHeight / maxHeight) {
                        width = maxWidth;
                        height = Math.round((naturalHeight / naturalWidth) * maxWidth);
                    } else {
                        height = maxHeight;
                        width = Math.round((naturalWidth / naturalHeight) * maxHeight);
                    }
                }

                resolve({ width, height });
            };
        });
    };

    const handleAnswerChange = (index, newAnswer) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].answer = newAnswer;
        setQuestions(updatedQuestions);
    };

    const handleSave = async () => {
        let sortid = 1;
        const questionMaxWidth = 600;
        const questionMaxHeight = 900;
        const optionMaxWidth = 600;
        const optionMaxHeight = 900;

        const docSections = [];

        // Clone questions to avoid direct mutation and potential cyclic references
        const clonedQuestions = JSON.parse(JSON.stringify(questions));

        for (let index = 0; index < clonedQuestions.length; index++) {
            const question = clonedQuestions[index];

            // Process question image
            const questionImageTransform = question.questionImage
                ? await processImage(question.questionImage, questionMaxWidth, questionMaxHeight)
                : null;

            // Process solution image
            const solutionImageTransform = question.solutionImage
                ? await processImage(question.solutionImage, questionMaxWidth, questionMaxHeight)
                : null;

            const questionTextRun = question.questionImage
                ? new ImageRun({
                      data: question.questionImage.split(",")[1],
                      transformation: questionImageTransform,
                  })
                : new TextRun(question.questionText || "");

            const solutionTextRun = question.solutionImage
                ? new ImageRun({
                      data: question.solutionImage.split(",")[1],
                      transformation: solutionImageTransform,
                  })
                : new TextRun(question.solutionText || "");

            const questionPart = new TextRun({ text: "[Q] ", bold: true });
            const solutionPart = new TextRun({ text: "    [soln] ", bold: true });

            const questionParagraph = new Paragraph({
                children: [
                    questionPart,
                    questionTextRun,
                ],
            });

            const solutionParagraph = new Paragraph({
                children: [
                    solutionPart,
                    solutionTextRun,
                ],
            });

            // Create option paragraphs
            const optionParagraphs = [];
            for (let i = 0; i < question.options.length; i++) {
                const option = question.options[i];
                const label = `(${String.fromCharCode(97 + i)}) `;
                const optionTransform = option.image
                    ? await processImage(option.image, optionMaxWidth, optionMaxHeight)
                    : null;

                optionParagraphs.push(
                    new Paragraph({
                        children: [
                            new TextRun({ text: label, bold: true }),
                            option.image
                                ? new ImageRun({
                                      data: option.image.split(",")[1],
                                      transformation: optionTransform,
                                  })
                                : new TextRun(option.text),
                        ],
                    })
                );
            }

            // Add question, options, and answer to document sections
            docSections.push({
                children: [
                    questionParagraph,
                    ...optionParagraphs,
                    new Paragraph(`[qtype] mcq`),
                    new Paragraph({ text: `[ans] ${question.answer}`, bold: true }),
                    new Paragraph(`[Marks] 3,1`),
                    new Paragraph(`[sortid] ${sortid++}`),
                    solutionParagraph,
                ],
            });
        }

        docSections.push({
            children: [new Paragraph(`[QQ]`)],
        });

        const doc = new Document({
            sections: docSections,
        });

        try {
            const blob = await Packer.toBlob(doc);
            saveAs(blob, "question_with_options.docx");
            alert("Document has been downloaded successfully!");
        } catch (error) {
            console.error("Error creating the document:", error);
        }
    };

    const handlePaste = (e, setImage, index) => {
        e.preventDefault();
        const clipboardItems = e.clipboardData.items;
        for (let i = 0; i < clipboardItems.length; i++) {
            if (clipboardItems[i].type.startsWith("image/")) {
                const file = clipboardItems[i].getAsFile();
                const reader = new FileReader();
                reader.onload = () => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[index].solutionImage = reader.result;
                    setQuestions(updatedQuestions);
                };
                reader.readAsDataURL(file);
                break;
            }
        }
    };

    const addNewQuestion = () => {
        if (questions.length < 30) {
            setQuestions([
                ...questions,
                {
                    questionImage: null,
                    options: [
                        { text: "", image: null },
                        { text: "", image: null },
                        { text: "", image: null },
                        { text: "", image: null },
                    ],
                    solutionImage: null,
                    answer: "",
                },
            ]);
            setQuestionBgColors([...questionBgColors, "#fff"]);
            setSolutionBgColors([...solutionBgColors, "#fff"]);
            setOptionBgColors([...optionBgColors, ["#fff", "#fff", "#fff", "#fff"]]);
            setQuestionActiveStatuses([...questionActiveStatuses, false]);
            setSolutionActiveStatuses([...solutionActiveStatuses, false]);
            setOptionActiveStatuses([...optionActiveStatuses, [false, false, false, false]]);
        } else {
            alert("You can only add up to 30 questions.");
        }
    };

    const handleOptionPaste = (e, index, optionIndex) => {
        e.preventDefault();
        const clipboardItems = e.clipboardData.items;
        for (let i = 0; i < clipboardItems.length; i++) {
            if (clipboardItems[i].type.startsWith("image/")) {
                const file = clipboardItems[i].getAsFile();
                const reader = new FileReader();
                reader.onload = () => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[index].options[optionIndex].image = reader.result;
                    updatedQuestions[index].options[optionIndex].text = "";
                    setQuestions(updatedQuestions);
                };
                reader.readAsDataURL(file);
                break;
            } else if (clipboardItems[i].type === "text/plain") {
                const text = e.clipboardData.getData("text");
                const updatedQuestions = [...questions];
                updatedQuestions[index].options[optionIndex].text = text;
                updatedQuestions[index].options[optionIndex].image = null;
                setQuestions(updatedQuestions);
                break;
            }
        }
    };

    const toggleQuestionBgColor = (index) => {
        const updatedQuestionActiveStatuses = [...questionActiveStatuses];
        updatedQuestionActiveStatuses[index] = !updatedQuestionActiveStatuses[index];
        setQuestionActiveStatuses(updatedQuestionActiveStatuses);

        const updatedQuestionBgColors = [...questionBgColors];
        updatedQuestionBgColors[index] = updatedQuestionActiveStatuses[index] ? "#f0f0f0" : "#cfdbdf";
        setQuestionBgColors(updatedQuestionBgColors);
    };

    const toggleSolutionBgColor = (index) => {
        const updatedSolutionActiveStatuses = [...solutionActiveStatuses];
        updatedSolutionActiveStatuses[index] = !updatedSolutionActiveStatuses[index];
        setSolutionActiveStatuses(updatedSolutionActiveStatuses);

        const updatedSolutionBgColors = [...solutionBgColors];
        updatedSolutionBgColors[index] = updatedSolutionActiveStatuses[index] ? "#f0f0f0" : "#cfdbdf";
        setSolutionBgColors(updatedSolutionBgColors);
    };

        const toggleOptionBgColor = (questionIndex, optionIndex) => {
            const updatedOptionActiveStatuses = [...optionActiveStatuses];
            updatedOptionActiveStatuses[questionIndex][optionIndex] = !updatedOptionActiveStatuses[questionIndex][optionIndex];
            setOptionActiveStatuses(updatedOptionActiveStatuses);
    
            const updatedOptionBgColors = [...optionBgColors];
            updatedOptionBgColors[questionIndex][optionIndex] = updatedOptionActiveStatuses[questionIndex][optionIndex] ? "#f0f0f0" : "#cfdbdf";
            setOptionBgColors(updatedOptionBgColors);
        };
    
        return (
            <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", maxWidth: "600px", margin: "auto" }}>
                <h2>Paste Question and Options</h2>
                {questions.map((question, index) => (
                    <div key={index} style={{ marginBottom: "30px" }}>
                        <h3>Question {index + 1}</h3>
    
                        {/* Paste Image for Question */}
                        <div style={{ marginBottom: "20px" }}>
                            <h3>Paste Image for Question</h3>
                            <div
                                style={{
                                    border: "1px dashed #ccc",
                                    padding: "10px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    backgroundColor: questionBgColors[index], // Apply question background color
                                }}
                                onClick={() => toggleQuestionBgColor(index)} // Toggle question background color on click
                                onPaste={(e) => {
                                    const clipboardItems = e.clipboardData.items;
                                    for (let i = 0; i < clipboardItems.length; i++) {
                                        if (clipboardItems[i].type.startsWith("image/")) {
                                            const file = clipboardItems[i].getAsFile();
                                            const reader = new FileReader();
                                            reader.onload = () => {
                                                const updatedQuestions = [...questions];
                                                updatedQuestions[index].questionImage = reader.result;
                                                setQuestions(updatedQuestions);
                                            };
                                            reader.readAsDataURL(file);
                                            break;
                                        }
                                    }
                                }}
                            >
                                {question.questionImage ? (
                                    <img src={question.questionImage} alt={`Question ${index + 1}`} style={{ maxWidth: "100%", height: "auto" }} />
                                ) : (
                                    "Paste your question image here (Ctrl+V)"
                                )}
                            </div>
                        </div>
                        {question.options.map((option, optionIndex) => (
                            <div key={optionIndex} style={{ marginBottom: "10px" }}>
                                <label>
                                    <strong>Option {String.fromCharCode(65 + optionIndex)}:</strong>
                                </label>
                                <div
                                    style={{
                                        border: "1px dashed #ccc",
                                        padding: "10px",
                                        cursor: "pointer",
                                        textAlign: "center",
                                        backgroundColor: optionBgColors[index][optionIndex], // Apply option background color
                                    }}
                                    onClick={() => toggleOptionBgColor(index, optionIndex)} // Toggle option background color on click
                                    onPaste={(e) => handleOptionPaste(e, index, optionIndex)}
                                >
                                    {option.image ? (
                                        <img
                                            src={option.image}
                                            alt={`Option ${String.fromCharCode(65 + optionIndex)}`}
                                            style={{ maxWidth: "100%", height: "auto" }}
                                        />
                                    ) : (
                                        option.text || `Paste text or image for Option ${String.fromCharCode(65 + optionIndex)}`
                                    )}
                                </div>
                            </div>
                        ))}
    
                        {/* Paste Solution Image */}
                        <div style={{ marginBottom: "20px" }}>
                            <h3>Solution</h3>
                            <div
                                style={{
                                    border: "1px dashed #ccc",
                                    padding: "10px",
                                    cursor: "pointer",
                                    backgroundColor: solutionBgColors[index], // Apply solution background color
                                }}
                                onClick={() => toggleSolutionBgColor(index)} // Toggle solution background color on click
                                onPaste={(e) => handlePaste(e, setQuestions, index)}
                            >
                                {question.solutionImage ? (
                                    <img src={question.solutionImage} alt={`Solution ${index + 1}`} style={{ maxWidth: "100%", height: "auto" }} />
                                ) : (
                                    "Paste your solution image here (Ctrl+V)"
                                )}
                            </div>
                        </div>
    
                        {/* Answer Selection */}
                        <div style={{ marginBottom: "20px" }}>
                            <label>
                                <strong>Answer:</strong>
                            </label>
                            <select
                                value={question.answer}
                                onChange={(e) => handleAnswerChange(index, e.target.value)}
                                style={{ width: "100%", padding: "10px", fontSize: "16px" }}
                            >
                                <option value="">Select Answer</option>
                                <option value="a">A</option>
                                <option value="b">B</option>
                                <option value="c">C</option>
                                <option value="d">D</option>
                            </select>
                        </div>
                    </div>
                ))}
                <button
                    onClick={addNewQuestion}
                    style={{
                        backgroundColor: "#4CAF50",
                        color: "white",
                        padding: "10px 20px",
                        border: "none",
                        cursor: "pointer",
                    }}
                >
                    Add New Question
                </button>
                <br />
                <button
                    onClick={handleSave}
                    style={{
                        backgroundColor: "#2196F3",
                        color: "white",
                        padding: "10px 20px",
                        border: "none",
                        cursor: "pointer",
                       margin: "20px",
                    }}
                >
                    Save Document
                </button>
            </div>
        );
    };
    
    export default SectionADocumentCreationBitSat ;