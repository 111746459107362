import '../AhaGuru_CardDesigns/Styles/AhaGuru_cardDesign1.css';
import card1img from "./assests/card1img.png"
export function AhaGuruCard1(){
    return(
        <div className="mainDiv1">
            <div className="cardTop1">
                <div className="img1">
                    <img src={card1img} alt="image"/>
                </div> 
                <h2>Physics</h2>
            </div>
            <div className="cardBottom1">
                <h1 className="mainHeading1">IIT JEE + CBSE + NEET</h1>
                <h3 className="scheduleHeading1">Class Schedule</h3>
                <p>Batch: P3</p>
                <p>Class 11</p>
                <p>Saturday 6:00 pm - 9:00 pm</p>
                <div className="viewDetails1">
                    <div className='link1'>
                        <a href="#">View Details</a>
                    </div>
                    <div>
                        <input type="checkbox" id="details1" name="details1" value="details1" className="custom-checkbox1"/>
                        <label for="details1">Select</label>
                    </div>
                </div>
            </div>
        </div>
    )
}