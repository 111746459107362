import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Egate } from "./Landing_PAGE/Egate";
import SectionADocumentCreationBitSat from "./MockTest/SectionADocumentCreationBitSat.jsx";
import SectionACreationNEET from "./MockTest/SectionACreationNEET.jsx";
import SectionBCreationNEET from "./MockTest/SectionBCreationNEET.jsx";
import UploadFormatsMain from "./MockTest/UploadFormatsMain.jsx";
import UploadNeetDocument from "./MockTest/UploadNeetDocument.jsx";
import BitSatSectionA from "./MockTest/BitSatSectionA.jsx";
import LoadingSpinner from './LoadingSpinner.jsx'
import { ExamTerminated } from "./MockTest/ExamTerminated.jsx"
import CardsAhaguru from "./AhaGuru_CardDesigns/CardsAhaguru.jsx";
const JsonFetchingFromBlob = React.lazy(() =>
  import("./Blob/JsonFetchingFromBlob.jsx")
);
const Practicemainpage = React.lazy(() =>
  import("./Practice page/practicemainpage.jsx")
);
const SectionADocumentCreation = React.lazy(() =>
  import("./components/ug_homepage/components/Downloads/SectionADocumentCreation.jsx")
);
const SectionBDocumentCreation = React.lazy(() =>
  import("./components/ug_homepage/components/Downloads/SectionBDocumentCreation.jsx")
);
const Management = React.lazy(() =>
  import("./components/ug_homepage/components/Downloads/Upload Formats/Management.jsx")
);
const PageNotFound = React.lazy(() =>
  import("./MockTest/PageNotFound.jsx")
);
const PracticeLandingpage = React.lazy(() =>
  import("./Practice page/PracticeLandingpage.jsx")
);
const DocumentUpload = React.lazy(() =>
  import("./components/ug_homepage/components/Downloads/DocumentUpload.jsx")
);
const CovertJson = React.lazy(() => import("./MockTest/CovertJson.jsx"));
const StudentForm = React.lazy(() => import("./MockTest/StudentForm.jsx"));
const GeneralInstructionsPage = React.lazy(() =>
  import("./MockTest/GeneralInstructionsPage")
);
const UgExamHomePage = React.lazy(() =>
  import("./ExamHomePage/UgExamHomePage.jsx")
);
const OnlineRecordedLecturesHomePage = React.lazy(() =>
  import(
    "./ExamHomePage/OnlineRecordedLecturesHomePages/OnlineRecordedLecturesHomePage.jsx"
  )
);
const OnlineVideoLecturesHomePage = React.lazy(() =>
  import("./OnlineVideoLecturesHomePage/OnlineVideoLecturesHomePage.jsx")
);
const OnlineTestSerieshome = React.lazy(() =>
  import("./OnlineTestSeriesHomePages/OnlineTestSerieshome.jsx")
);
const UG_HOME = React.lazy(() => import("./components/ug_homepage/UG_HOME"));
const Pg_home_page = React.lazy(() =>
  import("./components/PG/PG_homePage/Pg_home_page").then((module) => ({
    default: module.Pg_home_page,
  }))
);
const State = React.lazy(() =>
  import("./components/PG/context/State.jsx").then((module) => ({
    default: module.State,
  }))
);
const ScrollToTop = React.lazy(() => import("./Landing_PAGE/ScrollToTop.js"));


const Pricing_Policy = React.lazy(() =>
  import("./components/UG/Pricing_Policy.jsx")
);
const Contact = React.lazy(() =>
  import("./components/ug_homepage/components/Contact/Contact.js")
);
const About_us = React.lazy(() =>
  import("./components/ug_homepage/components/About_section/About_us.jsx")
);
const TERMsCONDITIONS = React.lazy(() =>
  import("./components/UG/TERMsCONDITIONS").then((module) => ({
    default: module.TERMsCONDITIONS,
  }))
);
const Privacypolicy = React.lazy(() =>
  import("./components/UG/Privacypolicy").then((module) => ({
    default: module.Privacypolicy,
  }))
);
const CancellationRefundPolicy = React.lazy(() =>
  import("./components/UG/CancellationRefundPolicy.jsx")
);
const JeeVideoLectures = React.lazy(() =>
  import("./VideoLectures/JeeVideoLectures.jsx")
);
const JeeMathsTopics = React.lazy(() =>
  import("./VideoLectures/JeeMathsTopics.jsx")
);

const OurOfferingsPage = React.lazy(() =>
  import("./Landing_PAGE/OurOfferingsPage.jsx")
);
const Careers_file = React.lazy(() =>
  import("./Landing_PAGE/Careers_file.jsx")
);

const OnlineTestSeriesRootFile = React.lazy(() =>
  import("./MockTest/OnlineTestSeriesRootFile")
);
const OTSViewReport = React.lazy(() => import("./MockTest/OTSViewReport"));


const ExamInstructions = React.lazy(() =>
  import("./MockTest/ExamInstructionsPage.jsx")
);

const App = () => {


  return (

    <State>
      <Router>
        <ScrollToTop />
        <Routes>
          {/* This is a link for landing page */}
          <Route path="/" element={<Egate />} />
          <Route
            path="/OurOfferingsPage"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <OurOfferingsPage />
              </Suspense>
            }
          />
          <Route
            path="/Careers_file"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Careers_file />
              </Suspense>
            }
          />

          {/* ********************* UG EXAM HOME PAGES ************************************* */}
          <Route
            path="/UGHome"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <UG_HOME />
              </Suspense>
            }
          />
          <Route
            path="/UgExamHomePage/:examId"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <UgExamHomePage />
              </Suspense>
            }
          />
          <Route
            path="/OnlineRecordedLecturesHomePage/:examId"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <OnlineRecordedLecturesHomePage />
              </Suspense>
            }
          />

          <Route
            path="/OnlineTestSerieshome/:examId"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <OnlineTestSerieshome />
              </Suspense>
            }
          />
          <Route
            path="/OnlineVideoLecturesHomePage/:examId"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <OnlineVideoLecturesHomePage />
              </Suspense>
            }
          />
          <Route
            path="/PGHome"
            element={
              <Suspense fallback={<div>Loading ...</div>}>
                <Pg_home_page />
              </Suspense>
            }
          />
          {/* *********************************FOOTER FILES********************* */}
          <Route
            path="/contactUs"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Contact />
              </Suspense>
            }
          />
          <Route
            path="/About_us"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <About_us />
              </Suspense>
            }
          />
          <Route
            path="/terms"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <TERMsCONDITIONS />
              </Suspense>
            }
          />
          <Route
            path="/CancellationRefundPolicy"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <CancellationRefundPolicy />
              </Suspense>
            }
          />
          <Route
            path="/policy"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                {" "}
                <Privacypolicy />
              </Suspense>
            }
          />
          <Route
            path="/pricingpolicy"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Pricing_Policy />{" "}
              </Suspense>
            }
          />
          {/* *****************************FOOTER FILES END*************************  */}

          <Route path="/SectionADocumentCreationBitsat" element={<SectionADocumentCreationBitSat/>} />
          <Route path="/SectionACreationNEET" element={<SectionACreationNEET/>} />
          <Route path="/SectionBCreationNEET" element={<SectionBCreationNEET/>}/>
          
          <Route path="/UploadFormatsMain" element={<UploadFormatsMain/>}/>
          <Route path="/BitSat" element={<BitSatSectionA/>}/>
          <Route path="/UploadNeetDocument" element={<UploadNeetDocument/>}/>

          <Route
            path="/CovertJson"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <CovertJson />
              </Suspense>
            }
          />
          <Route
            path="/UGMockTest"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <StudentForm />
              </Suspense>
            }
          />
          <Route
            path="/GeneralInstructionsPage/:examId/:mockTestId/:mockTestTypeId"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <GeneralInstructionsPage />
              </Suspense>
            }
          />
          <Route
            path="/PageNotFound"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <PageNotFound />
              </Suspense>
            }
          />


          <Route
            path="/VideoLectures"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <JeeVideoLectures />
              </Suspense>
            }
          />
          <Route
            path="/JeeMathsTopics/:exam/:subject"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <JeeMathsTopics />
              </Suspense>
            }
          />
          <Route path='/ExamTerminated' element={<ExamTerminated />} />

          <Route
            path="/blob"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <JsonFetchingFromBlob />
              </Suspense>
            }
          />
          <Route
            path="/DocumentUpload"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <DocumentUpload />
              </Suspense>
            }
          />

          <Route
            path="/SectionADocumentCreation"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <SectionADocumentCreation />
              </Suspense>
            }
          />

          <Route
            path="/SectionBDocumentCreation"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <SectionBDocumentCreation />
              </Suspense>
            }
          />


          <Route
            path="/MockTest/:examId/:mockTestId/:mockTestTypeId"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                {" "}
                <OnlineTestSeriesRootFile />
              </Suspense>
            }
          />

          <Route
            path="/OTSViewReport/:examId/:mockTestId/:mockTestTypeId"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <OTSViewReport />
              </Suspense>
            }
          />

          <Route
            path="/JeeMainsInstructions/:examId/:mockTestId/:mockTestTypeId"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                {" "}
                <ExamInstructions />
              </Suspense>
            }
          />
          <Route
            path="/JeeAdvanceInstructions/:examId/:mockTestId/:mockTestTypeId"
            element={
              <Suspense fallback={<LoadingSpinner />}>

                <ExamInstructions />
              </Suspense>
            }
          />
          <Route
            path="/BitsatInstructions/:examId/:mockTestId/:mockTestTypeId"
            element={
              <Suspense fallback={<LoadingSpinner />}>

                <ExamInstructions />
              </Suspense>
            }
          />
          <Route
            path="/NeetInstructions/:examId/:mockTestId/:mockTestTypeId"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                {" "}
                <ExamInstructions />
              </Suspense>
            }
          />
          <Route
            path="/UploadFormats"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                {" "}
                <Management />
              </Suspense>
            }
          />
          <Route
            path="/Practice"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                {" "}
                <PracticeLandingpage />
              </Suspense>
            }
          />
          <Route
            path="/Practicemainpage/:id"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                {" "}
                <Practicemainpage />
              </Suspense>
            }
          />

          <Route path="/cssCards" element={<CardsAhaguru/>}/>

        </Routes>

      </Router>
    </State>
  );
};

export default App;
