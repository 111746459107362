import '../AhaGuru_CardDesigns/Styles/AhaGuru_cardDesign5.css';
import teacher1 from "./assests/teacher1.webp"
import { FaLaptop } from "react-icons/fa";
import { BsPcDisplay } from "react-icons/bs";
import { IoNewspaperOutline } from "react-icons/io5"
import { FaCalculator } from "react-icons/fa6";
import { BsFillPrinterFill } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { HiPencilAlt } from "react-icons/hi";
import { FaMobile } from "react-icons/fa6";
import { MdSquare } from "react-icons/md";

export function AhaGuruCard5(){
    return(
        <div className="mainDiv5">
            <div className="cardTop5">
                <div className='cardImgHolder5'>
                    <img src={teacher1} alt="image"/>
                </div>
                <div className='classdetailsHeader5'>
                    <h5>Class 11</h5>
                    <h5>Physics for IIT JEE + CBSE + NEET</h5>
                </div>    
            </div>
            <div className="cardBottom5">
                <p><FaLaptop className='CardIcons'/> Periodic LIVE Webinar Sessions</p>
                <hr className="customDivider"/>
                <p><BsPcDisplay className='CardIcons'/> 354 Video Lectures</p>
                <hr className="customDivider"/>
                <p><IoNewspaperOutline className='CardIcons'/> 104 Solved Video Examples</p>
                <hr className="customDivider"/>
                <p><FaCalculator className='CardIcons'/> 195 Practice Exercises</p>
                <hr className="customDivider"/>
                <p><BsFillPrinterFill className='CardIcons'/> Printed Study Materials</p>
                <hr className="customDivider"/>
                <p><FaHandshake className='CardIcons'/> Personalized Mentor Suppourt</p>
                <hr className="customDivider"/>
                <p><HiPencilAlt className='CardIcons'/> Monthly Consolidated Tests</p>
                <hr className="customDivider"/>
                <p><FaMobile className='CardIcons'/>Parents App to View Progress Reports</p>

                <div className="priceDetails5">
                    <div className="price5">
                        <h4>Rs. 12000<del className='notPrice5'>Rs. 15000</del></h4>
                    </div>
                    <div className="link5">
                        <a href="#">View Topics</a>
                    </div>
                </div>

                <div className="buyNowbtn5">
                    <button className='takeCourseBtn5'><MdSquare  className='squareIcon'/>Take this Course</button>
                </div>
            </div>
        </div>
    )
}