import '../AhaGuru_CardDesigns/Styles/AhaGuru_cardDesign6.css';
import card6img from "./assests/card6img.png"
export function AhaGuruCard6() {
    return (
        <div className="mainDiv6">
            <div className="cardTop6">
                <div className="cardimg6">
                    <img src={card6img} alt="img" />
                </div>
                <div className="cardHead6">
                    <h3>Physics</h3>
                    <p>IIT JEE + CBSE + NEET</p>
                </div>
            </div>
            <div className="cardBottom6">
                <div className='classHolder6'>
                    <h5 className='fieldset6'>Class Schedule</h5>
                    <div className='fieldSetContent6'>
                        <div className="schedule6">
                            <p>Class 11</p>
                            <p className='batch6'>Batch: P3</p>
                        </div>
                        <p className='paraP6'><i className="bi bi-calendar-check"></i> Saturday</p>
                        <p className='paraP6'><i className="bi bi-clock"></i> 6.00pm - 9.00 pm</p>
                    </div>
                </div>
                
                <div className="courseDetails6">
                    <div className="viewDetails6">
                        <button>View Details</button>
                    </div>
                    <div className='select6'>
                        <input type="checkbox" id="details6" name="details6" value="details6"  className="custom-checkbox6" />
                        <label for="details6" className='details6'>Select</label>
                    </div>
                </div>
            </div>
        </div>
    )
}